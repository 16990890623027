

import ComingSoon from '../../layout/coming-soon';
import style from './contractor.module.scss';

const ContractorComponent = () => {


  return (
    <>
    <ComingSoon />
    
    
    </>
  );
};

export default ContractorComponent;
