import React from 'react'
import './style.css'
const ClientCollaboration = () => {
  return (
    <div className='clinet-collaboration-wrapper'>
      
    </div>
  )
}

export default ClientCollaboration
