import React from 'react'
import './style.css';

const IndustryDifference = () => {
  return (
    <div className='industry-difference-wrapper'>
      
    </div>
  )
}

export default IndustryDifference
