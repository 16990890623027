import React from 'react'
import './style.css';
const TailoredMarketing = () => {
  return (
    <div className='tailder-marketing-agecines-wrapper'>
      
    </div>
  )
}

export default TailoredMarketing
