import React from 'react'
import './style.css';
const FinanicalManagement = () => {
  return (
    <div className='industry-category-financial-management'>
      
    </div>
  )
}

export default FinanicalManagement
