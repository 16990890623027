import React from 'react'
import './style.css';
const ScalableCustomizable = () => {
  return (
    <div className='scalable-customizable-wrppaer'> 
      
    </div>
  )
}

export default ScalableCustomizable
